import { Controller } from "@hotwired/stimulus";
class index extends Controller {
  replace(event) {
    event.preventDefault();
    event.stopPropagation();
    const [, , xhr] = event.detail;
    this.element.outerHTML = xhr.response;
  }
  append(event) {
    event.preventDefault();
    event.stopPropagation();
    const [, , xhr] = event.detail;
    this.element.insertAdjacentHTML("afterend", xhr.response);
  }
  prepend(event) {
    event.preventDefault();
    event.stopPropagation();
    const [, , xhr] = event.detail;
    this.element.insertAdjacentHTML("beforebegin", xhr.response);
  }
}
export { index as default };
